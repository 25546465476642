/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/jquery-ui@1.13.2/dist/jquery-ui.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
